import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';
import { HtmlExcerptPipe } from './html-excerpt.pipe';

@Pipe({
  name: 'mdExcerpt',
})
export class MdExcerptPipe implements PipeTransform {
  constructor(private readonly htmlExcerptPipe: HtmlExcerptPipe) {}

  transform(value: string, length = 55): string {
    if (!value) {
      return '';
    }

    const htmlString = marked.parse(value) as string;
    return this.htmlExcerptPipe.transform(htmlString, length);
  }
}
