import { Pipe, PipeTransform } from '@angular/core';
import { HtmlToTextPipe } from './html-to-text.pipe';

@Pipe({
  name: 'htmlExcerpt',
})
export class HtmlExcerptPipe implements PipeTransform {
  constructor(private readonly htmlToTextPipe: HtmlToTextPipe) {}

  transform(value: string, length = 55): string {
    if (!value) {
      return '';
    }

    const contentString = this.htmlToTextPipe.transform(value);
    const excerpt = contentString.split(' ').slice(0, length);
    if (excerpt.length >= length) {
      excerpt.push('[...]');
    }

    return excerpt.join(' ');
  }
}
